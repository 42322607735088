import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["GtagLink"] */ "/vercel/path0/src/components/fixedWhatsapp/GtagLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/footer/newsletter/Newsletter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReactThemeToggleButton"] */ "/vercel/path0/src/components/reactThemeToggle/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LandingContactForm"] */ "/vercel/path0/src/contactForm/landing/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GtagLink"] */ "/vercel/path0/src/sections/hero/GtaLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GtagLink"] */ "/vercel/path0/src/sections/howSection/GtagLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToggleModalBtn"] */ "/vercel/path0/src/sections/services/ToggleModalBtn.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/sections/hero.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/sections/howSection.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/sections/services.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/sections/about.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/sections/features.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/sections/contact.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/sections/blog.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/sections/map.scss");
