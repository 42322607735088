'use client';
import { sendGTMEvent } from '@next/third-parties/google';

export const GtagLink = ({ texto, spriteName }: { texto: string; spriteName: string }) => {
	const gtag_report_conversion = (url: string) => {
		if (typeof window.gtag === 'function') {
			window.gtag('event', 'conversion', {
				'send_to': 'AW-16577154782/58-TCNaazLMZEN6dzeA9',
				'event_callback': () => {
					window.open(url, '_blank', 'noopener,noreferrer');
				}
			});
		} else {
			window.open(url, '_blank', 'noopener,noreferrer'); // Fallback si gtag no está definido
		}
	};
	const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		event.preventDefault(); // Previene la navegación predeterminada
		gtag_report_conversion('https://wa.link/63oe8r');
	};
	return (
		<a href="https://wa.link/63oe8r" target="_blank" onClick={handleLinkClick}>
			<span>{texto}</span>
			<svg>
				<use href={`/svgs/sprite.svg#${spriteName}`}></use>
			</svg>
		</a>
	);
};
