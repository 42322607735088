'use client';

import { useState } from 'react';
import { ServiceModal } from './ServiceModal';
import Image from 'next/image';

interface Props {
	popupServicio: {
		titulo: string;
		descripcion: string;
		imagen: {
			data: {
				attributes: {
					hash: string;
					url: string;
				};
			};
		};
	};
}

export const ToggleModalBtn = ({ popupServicio }: Props) => {
	const [serviceModalOpen, setServiceModalOpen] = useState(false);
	const gtag_report_conversion = (url: string) => {
		if (typeof window.gtag === 'function') {
			window.gtag('event', 'conversion', {
				'send_to': 'AW-16577154782/58-TCNaazLMZEN6dzeA9',
				'event_callback': () => {
					window.open(url, '_blank', 'noopener,noreferrer');
				}
			});
		} else {
			window.open(
				'https://wa.me/56956306291?text=Hola,%20me%20interesa%20obtener%20m%C3%A1s%20informaci%C3%B3n%20acerca%20de%20sus%20servicios',
				'_blank',
				'noopener,noreferrer'
			); // Fallback si gtag no está definido
		}
	};
	const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		event.preventDefault(); // Previene la navegación predeterminada del enlace
		gtag_report_conversion(
			'https://wa.me/56956306291?text=Hola,%20me%20interesa%20obtener%20m%C3%A1s%20informaci%C3%B3n%20acerca%20de%20sus%20servicios'
		);
	};

	return (
		<div className="toggle-modal-container">
			<button onClick={() => setServiceModalOpen(true)}>Leer más</button>
			{serviceModalOpen ? (
				<ServiceModal isOpen={serviceModalOpen} handleClose={() => setServiceModalOpen(!serviceModalOpen)}>
					<picture>
						<Image fill src={popupServicio.imagen.data.attributes.url} alt="Imagen del servicio" loading="eager" />
					</picture>
					<div className="modal-text-box">
						<h4>{popupServicio.titulo}</h4>
						<p>{popupServicio.descripcion}</p>
						<a
							target="_blank"
							aria-label="Obtener más información"
							href={
								'https://wa.me/56956306291?text=Hola,%20me%20interesa%20obtener%20m%C3%A1s%20informaci%C3%B3n%20acerca%20de%20sus%20servicios'
							}
							onClick={(e) => {
								setServiceModalOpen(!serviceModalOpen);
								handleLinkClick(e);
							}}
							rel="noopener noreferrer"
							className="modal-link">
							Más información
						</a>
					</div>
				</ServiceModal>
			) : null}
		</div>
	);
};
